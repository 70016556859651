import Consts from './Consts';

const Dialogs = {
  LOST_STOLEN: {
    linkSubTitle:
      "Have you tried locating your corporate mobile device at Walmart's ",
    title: 'Lost/Stolen Device',
    subCopy: 'Device found?',
    subTitleWithLinkText: 'Self Service Device Portal?',
    secondaryInputText: 'No, I need replacement device.',
    subTitle: '',
    primaryInputText: 'Yes, I found it!',
  },

  POWER: {
    linkSubTitle:
      'Please try re-starting your device or a different power outlet. If you have an Apple device you can find troubleshooting instructions ',
    title: 'Power',
    subCopy: 'Did that resolve your issue?',
    subTitleWithLinkText: 'here.',
    secondaryInputText: 'No, I need a replacement device.',
    subTitle: '',
    primaryInputText: 'Yes, resolved!',
  },
  PROCURE_IT: {
    title: 'Procure-It',
    gecAssociateSubTitle:
      'GEC Associates are required to use Procure-It when ordering Corporate iPads. Please click the button below to be routed to Procure-It.',
    deviceSelectedOtherSubTitle:
      'iPads not listed in the dropdown are considered special orders. Please click the button below to be routed to Procure-It.',
  },
  DAMAGE: {
    title: 'Damaged/Broken Device',
    secondaryInputText: Consts.REPLACEMENT_DIALOGS.SECONDARY_INPUT_TEXT,
    subTitle: Consts.REPLACEMENT_DIALOGS.SUBTITLE,
    primaryInputText: Consts.YES,
  },
  BATTERY: {
    title: 'Battery Issues',
    secondaryInputText: Consts.REPLACEMENT_DIALOGS.SECONDARY_INPUT_TEXT,
    subTitle: Consts.REPLACEMENT_DIALOGS.SUBTITLE,
    primaryInputText: Consts.YES,
  },
  REFRESHES: {
    title: 'Refresh Device',
    secondaryInputText: Consts.REPLACEMENT_DIALOGS.SECONDARY_INPUT_TEXT,
    subTitle: Consts.REPLACEMENT_DIALOGS.SUBTITLE,
    primaryInputText: Consts.YES,
  },
  RETURN: {
    TITLE: 'Returning your device?',
    SUBTEXT:
      'You can return your Corporate phone, along with any accessories that came with it, to the below address:',
    SHIPPING: {
      ATTENTION: 'Attention: Corporate Device Returns',
      BUILDING: 'Walmart Home Office ',
      ADDRESS: '702 SW 8th St',
      CITY: 'Bentonville',
      STATE: 'AR',
      ZIP: '72716-0285',
    },
    NOTE: 'Home Office associates can ship Interoffice to mailstop 285',
  },
  CANCEL_CORPORATE: {
    TITLE: 'Cancel Service',
    SUBTITLE:
      'This will cancel your request for the corporate device you are using or have ordered.',
    SUBMISSION_STATEMENT:
      'After submitting this request you will recieve an email with further instructions.',
    SUCCESS_TITLE: 'Device Cancelled',
    getSuccessDialogText: bool => {
      if (bool) {
        return `Your device has been successfully cancelled.`;
      } else {
        return `The device has been successfully cancelled.`;
      }
    },
  },
  ADMIN_RIGHTS_REQUEST: {
    SUCCESS_TITLE: 'Admin Rights Requested',
    TEXT_MESSAGE: "Your device's admin rights have been successfully requested",
  },
  EXTEND_LOANER: {
    SUCCESS_TITLE: 'Device Extended',
    TEXT_MESSAGE: 'Your device has been successfully extended.',
  },
  REBOOT_VDI: {
    CONFIRM_TITLE: 'Are you sure you want to reboot your VDI?',
    CONFIRM_MESSAGE: 'Any unsaved data will be lost.',
    SUCCESS_TITLE: 'VDI Rebooted',
    SUCCESS_TEXT: vdiName => {
      return `Initiated restart of VDI ${vdiName}. Please wait a few minutes and re-launch your VDI.`;
    },
  },
  RESTORE_VDI: {
    CONFIRM_TITLE: 'Are you sure you want to restore your VDI?',
    CONFIRM_MESSAGE: '',
    SUCCESS_TITLE: 'VDI Restored',
    SUCCESS_TEXT: vdiName => {
      return `Initiated restore of VDI ${vdiName}. Please wait for 2 hours and re-launch your VDI.`;
    },
  },
  CHANGE_2FA: {
    FORM_TITLE: 'Change 2FA Method',
    SUCCESS_TITLE: '2FA Method Change Requested',
    TEXT_MESSAGE:
      "Your device's 2FA method change has been successfully requested",
  },
  INTERNATIONAL: {
    FORM_TITLE: 'Select Travel Dates',
    getIntroductionTitle: type => {
      switch (type) {
        case Consts.INTERNATIONAL_TYPES.EXTEND:
          return 'Extend International Data Plan?';
        case Consts.INTERNATIONAL_TYPES.ADD:
          return 'Add International Data Plan?';
        default:
          return;
      }
    },
    getIntroductionStatement: type => {
      switch (type) {
        case Consts.INTERNATIONAL_TYPES.EXTEND:
          return 'Enjoy your trip! If you extend this plan, it will be auto cancelled on your expected travel return date.';
        case Consts.INTERNATIONAL_TYPES.ADD:
          return 'Enjoy your trip! If you add this plan, it will be auto cancelled on your expected travel return date.';
        default:
          return;
      }
    },
    getIntroductionContinueButton: type => {
      switch (type) {
        case Consts.INTERNATIONAL_TYPES.EXTEND:
          return 'Extend';
        case Consts.INTERNATIONAL_TYPES.ADD:
          return 'Add Plan';
        default:
          return;
      }
    },
    getCarrierServiceStatement: carrier => {
      switch (carrier) {
        case Consts.CARRIER_TYPES.ATT:
          return '*Please note that AT&T has an International Pay-Per-Use Plan already included. No need to add plan.';
        case Consts.CARRIER_TYPES.T_MOBILE:
          return '*Please note that T-Mobile has an International Pay-Per-Use Plan already included. No need to add plan.';
        default:
          return Consts.EMPTY;
      }
    },
    CALENDAR_SELECTION_STATEMENT:
      'Select your expected departure date and expected return date.',
    SUCCESS_TITLE: 'International Plan Added',
    getSuccessDialogText: type => {
      switch (type) {
        case Consts.INTERNATIONAL_TYPES.EXTEND:
          return `Your International Data Plan has been successfully extended.`;
        case Consts.INTERNATIONAL_TYPES.ADD:
          return `Your International Data Plan has been successfully added.`;
        default:
          return;
      }
    },
  },
  LOANER: {
    getSuccessDialogText: deliveryType => {
      switch (deliveryType) {
        case Consts.MAILSTOP:
          return 'Your order has been submitted. Further notification will be sent in email when your device is ready for pickup.';
        case Consts.PICKUP:
          return 'We will notify you when you can pick up your loaner device before your trip.';
        default:
          return;
      }
    },
  },
  CORPORATE: {
    SUCCESS_DIALOG_TEXT:
      'Your order has been submitted for approval. Further instructions and device tracking information will be sent in email after approval.',
  },

  SIM: {
    SUCCESS_DIALOG_TEXT:
      'Your request has been sent for approval. Further details will be sent in your email.',
  },
  IPAD: {
    getSuccessDialogText:
      'Your request has been sent for approval, which can take up to 5 days. After approval, it can take an additional 5 days to receive the device. Further details will be sent to your email.',
  },
  VDI_TECH_SUPPORT: {
    SUCCESS_TITLE: 'Request Submitted',
    SUCCESS_DIALOG_TEXT:
      'You can track status of your request under My Tickets.',
  },
  HOME_OFFICE_TECH_SUPPORT: {
    SUCCESS_TITLE: 'Request Submitted',
    SUCCESS_DIALOG_TEXT:
      'You can track status of your request under My Tickets.',
  },
  VIDEO_CONF_TECH_SUPPORT: {
    SUCCESS_TITLE: 'Request Submitted',
    SUCCESS_DIALOG_TEXT:
      'You can track the status of your request under My Tickets.',
  },
  HOTSPOT: {
    SUCCESS_DIALOG_TEXT:
      'Your request has been sent for manager approval. After approval, it can take an additional 5 days to receive the device.',
  },
  ONBOARDING: {
    SECURITY_COMPLETION_TITLE: `Great Job! You're tech is onboarded`,
    SECURITY_COMPLETION_SUBTITLE:
      'Check out the additional resources for more information on connecting you across the organization',
  },
  ADD_COMMENT: {
    SUCCESS_TITLE: 'Added Comment',
  },
  ATTACHMENT_DIALOG: {
    SUCCESS_TITLE: 'Attachments Uploaded',
  },
  DEVICES_INFO: {
    TITLE: 'My Devices',
  },

  HELP_CENTER_TECH_SUPPORT: {
    SUCCESS_TITLE: 'Request Submitted',
    SUCCESS_DIALOG_TEXT:
      'You can track status of your request under My Tickets.',
  },
  VIDEO_CAROUSEL_SEARCH_ERROR_DIALOG: {
    TITLE: 'Sorry!',
    MESSAGE:
      'It looks like the video you are looking for does not exist. Please check the spelling or select a video from the video carousel.',
  },
};

export default Dialogs;
