import PageTitles from '../../consts/PageTitles';
import Routes from '../../consts/Routes';
import Consts from '../../consts/Consts';

export const ERNav = isEquipmentRequestMFEEnabled => {
  return [
    {
      path: Consts.EMPTY,
      url: Routes.EQUIPMENT_REQUEST,
      isMFE: isEquipmentRequestMFEEnabled,
      disabled: !isEquipmentRequestMFEEnabled,
      label: PageTitles.EQUIPMENT_REQUEST,
      ignoreBreadcrumb: true,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      childPages: isEquipmentRequestMFEEnabled
        ? [
            {
              path: 'equipmentRequest/equipmentRequest',
              url: `${Routes.EQUIPMENT_REQUEST_LAPTOP}`,
              label: PageTitles.EQUIPMENT_REQUEST_LAPTOP,
              supportsLiteMode: true,
              disabled: false,
            },
          ]
        : null,
    },
  ];
};
