import EnvConfigs from '../Configurations';

const APIs = {
  BASE_API_URL: EnvConfigs.REACT_APP_BASE_API_URL,
  GRAPH_GATEWAY: 'graphGateway',
  FETCH_STORAGE_DATA: 'fetchStorageFiles',
  UPLOAD_STORAGE_DATA: 'uploadStorageFiles',
  GRAPHQL: 'graphql',
  MANAGER_DATA: 'managerData',
  COST_CENTER_DATA: 'fetchCostCenterData',
  SEARCH_API: 'mail-enabled-objects',
  USER_DATA: 'userData',
  PEOPLE_API: 'peopleApi',
  SEARCH_DATA: 'searchData',
  SITE_SEARCH_DATA: 'siteSearchData',
  SEARCH_DL: 'distribution-list/search',
  AD_DATA: 'ad',
  STORE_DATA: 'fetchStoreInfo',
  VDI_DATA: 'dwsData',
  VDI_REQUEST_DATA: 'dwsRequestData',
  VDI_REBOOT: 'restartDWS',
  VDI_RESTORE: 'restoreDWS',
  VDI_ADMIN_RIGHTS_REQUEST_DATA: 'dwsAdminRightsRequestData',
  VDI_NEW_ADMIN_RIGHTS_REQUEST: 'newDWSAdminRightsRequest',
  VDI_CHANGE_2FA_REQUEST: 'new2FARequest',
  LOGOUT: 'logout',
  COMMENTS: 'comments',
  LOOKUP_USER_DATA: 'lookupUserData',
  VALIDATE_PHONE_NUMBER: 'validatePhoneNumber',
  REHYDRATE_PHONE_NUMBER: 'rehydratePhoneNumber',
  USER_PRODUCTS: 'userProducts',
  PRODUCTS: 'products',
  PRODUCT_SUITES: 'productSuites',
  ATTACHMENT: (ticketTableName, serviceNowSysId) => {
    return `fileupload?name=${ticketTableName}&id=${serviceNowSysId}`;
  },
  DL: {
    GET_MEMBER: 'distribution-list/member',
    GET_RECOMMENDED: 'distribution-list?type=recommended',
    GET_STARTUP_CONFIG: 'distribution-list?type=startupconfig',
    GET_USER: 'distribution-list/user',
    GET_OWNED: 'distribution-list?type=owned',
    VALIDATE_DL: 'distribution-list/validate',
    SEARCH_DL_MAIL_ENABLED: 'distribution-list/search?type=mailenabled',
    CREATE_DL: 'distribution-list',
    JOIN_DL: 'distribution-list?action=join',
    GET_SUBSCRIBED: 'distribution-list/user',
    LEAVE_DL: 'distribution-list?action=leave',
    MODIFY_DL: 'distribution-list?action=modify',
    DELETE_DL: 'distribution-list',
  },
  SECURITY_ACCESS: {
    ENROLLMENT_2SV: 'verification-enrollment',
    ENROLLMENT_SSPR: 'active-directory',
    RESET_SSPR: 'reset-sspr-authmethods',
    PHISHING: 'phishingData',
  },
  CONFIG_TEXT: 'configText',
  APP_CONFIG: {
    FETCH: 'configText?action=fetch-config',
  },
  ADMIN: {
    UPDATE_CONFIG_TEXT: 'configText?action=update-config',
    UPDATE_FEATURE_CONFIG_TEXT: 'configText?action=update-feature-config',
    MANAGE_ORDERS: 'manageOrder',
    MOCK_DATA: 'mockdata',
    IMPERSONATE_USER: 'impersonateUser',
  },
  TICKETS: 'tickets',
  SHIPMENT_TRACKER: {
    USER_DATA: 'shipmentTrackerUserData',
    TRACKING_DATA: 'shipmentTrackerTrackingData',
  },
  DIGITAL_TWIN_APIS: {
    TECH_BAR_LOCATIONS: 'getDTTechBarLocations',
  },
  STORE_LOCATION: 'storeLocationData',
  getAPIUrl: function (apiName) {
    if (apiName) {
      return APIs.BASE_API_URL + apiName;
    } else {
      const errorObject = {
        message: 'Please provide api name',
        error: 'API_NAME_MISSING',
      };
      throw errorObject;
    }
  },
  GUIDE_TOGGLES: 'guideToggles',
  SEARCH_AD_GROUPS: searchTerm =>
    `searchData?searchCriteria=${searchTerm}&searchType=groups`,
  MYTECH_ADMIN: 'mytechAdmin',
};
export default APIs;
