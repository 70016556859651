const Consts = {
  MOBILE_TYPE: {
    CORPORATE: 'corporate',
    BYOD: 'byod',
    EMPTY: '',
  },
  MOBILE_FLOW: {
    CORPORATE: 'corporate',
    LOANER: 'loaner',
    INTERNATIONAL_PLAN: 'internationalplan',
    CANCEL_CORPORATE: 'cancelcorporate',
    DIALOG_TYPES: {
      BYOD: 'BYOD',
      NO_BYOD: 'NoBYOD',
    },
    IPAD: 'ipad',
    EAGLE_HOME: 'eagleAtHome',
  },
  CORPORATE_IPADS: {
    PROCURE_IT_DIALOG_TYPES: {
      GEC_ASSOCIATE: 'gecAssociate',
      DEVICE_SELECTED_OTHER: 'movileDeviceOther',
    },
  },
  SIM_FLOW: {
    SIM_ACTIVE_FLOW: 'SIM',
    NEW_SIM_REQUEST: 'newSIM',
    ACTIVATE_SIM_REQUEST: 'activateSIM',
    CHAR_LIMITS: {
      COMMENTS: '200',
      ADDRESS: '100',
      CITY: '45',
      ZIPCODE: '10',
      JUSTIFICATION: 500,
    },
  },
  REGEX: {
    ONLY_ALPHANUMERIC: /[0-9a-zA-Z]/,
    ONLY_20_DIGITS: /^\d{20}$/,
    ONLY_ONE_TO_5_DIGITS: /^\d{1,5}$/,
    ONLY_ONE_TO_4_DIGITS: /^\d{1,4}$/,
    SPECIAL_CHAR: /`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|>|\?|\/|"|;|:/g,
    PO_BOX: /\b[P|p]*(OST|ost)*\.*\s*[O|o|0]*(ffice|FFICE)*\.*\s*[B|b][O|o|0][X|x]\b/g,
    NUMBERS: /[0-9]+/g,
    LETTERS: /[A-Za-z]+/g,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ZIP_CODE: /^\d{5}(-)?(\d{4})?$/,
    CITY: /^[a-z#()@*$%!.,\s]+$/i,
    CONTACT_PHONE: /^(\d{1,3})?[- .]?\(?[2-9]\d{2}\)?[- .]?\d{3}[- .]?\d{4}$/, //Supports optional country code with an optional dash/space/dot, then DDD-DDD-DDDD
    AREA_CODE: /^[2-9]\d{2}$/,
    AREA_CODE_FIRST_DIGIT: /^[2-9]/,
    DEVICE_PHONE: /^[2-9]\d{9}$/,
    DEVICE_PHONE_LAST_10: /\d{10}$/,
    LEADING_PLUS: /^\+/g,
    USERS_UPN: /@.+?\..+?\./,
    NONALPHANUMERIC: /\W/g,
    SEARCH_USER: / - .* - /,
    SPECIAL_CHAR_AND_SPACES: / |-|`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|>|\?|\/|"|;|:/g,
  },
  PATTERN: {
    CONTACT_PHONE: '^[0-9-+s()]*$',
    DEVICE_PHONE: '\\d{10}',
    ZIPCODE: '^[0-9]*$',
    CITY: '^[a-zA-Z#()@*$%!.,][sa-zA-Z#()@*$%!.,]*$',
    ADDRESS: '^[0-9a-zA-Z#()@*$%!.,][s0-9a-zA-Z#()@*$%!.,]*$',
    AREA_CODE: '^[2-9]\\d{2}$',
  },
  MAXLENGTH: {
    DEVICE_PHONE: 10,
  },
  DEFAULT_VALUES: {
    GEC_AREA_CODE: '650',
  },
  CORE_ACTIONS: {
    DL_SEARCH_MAIL_ENABLED: 'mailenabled',
  },
  SEARCH: {
    DELAY_MS: 250,
    MIN_CHAR_LENGTH: 2,
  },
  REQUEST_TYPES: {
    LOANER: 'loaner',
    CORPORATE: 'corporate',
    REPLACEMENT: 'replacement',
    DAMAGE_REPLACEMENT: 'replacementdamaged',
    POWER_REPLACEMENT: 'replacementpower',
    LOST_STOLEN_REPLACEMENT: 'replacementloststolen',
    BATTERY_REPLACEMENT: 'replacementBattery',
    REFRESHES_REPLACEMENT: 'replacementRefreshes',
    INTERNATIONAL: 'international',
    INTERNATIONAL_PLAN: 'internationalplan', //Replaced with INTERNATIONAL when used with getRequestType
    SIM: 'sim',
    EAGLE_HOME: 'eagleAtHome',
    CANCEL: 'cancelcorporate',
    IPAD: 'ipad',
    TECH_SUPPORT_VDI: 'vditechsupport',
    TECH_SUPPORT_HOME_OFFICE: 'homeofficetechsupport',
    TECH_SUPPORT_VIDEO_CONF: 'videoconftechsupport',
    TECH_SUPPORT_APPLICATION: 'applicationtechsupport',
    TECH_SUPPORT_OTHER: 'othertechsupport',
    TECH_SUPPORT_DIGITAL_SIGNAGE: 'digitalsignagetechsupport',
    SNOW: 'snow',
    HOTSPOT: 'hotspot',
    DWS: 'dws',
    NEW_DEPARTMENT: 'New Department',
  },
  REPLACEMENT_TYPES: {
    DAMAGED: 'DAMAGE',
    LOST_STOLEN: 'LOST_STOLEN',
    POWER: 'POWER',
    BATTERY: 'BATTERY',
    REFRESHES: 'REFRESHES',
  },
  DEVICE_TYPES: {
    MOBILE: 'mobile',
    PC: 'pc',
    MAC: 'mac',
    DWS: 'dws',
  },
  DEVICE_OWNERSHIPS: {
    CORPORATE: 'Corporate Home Office',
    CORPORATE_SAMS: 'Corporate Sams Club',
    CORPORATE_DC: 'Corporate Distribution Center',
    LOANER: 'Home Office Dedicated Loaner',
    BYOD: 'BYOD Home Office',
    BYOD_OUTLOOK: 'BYOD Home Office Outlook',
    CORPORATE_STORE: 'Corporate Store',
  },
  ORDER_TYPES: {
    SAVE: 'save',
    SUBMIT: 'submit',
  },
  CARRIER_TYPES: {
    VERIZON: 'Verizon',
    ATT: 'AT&T',
    T_MOBILE: 'T-Mobile',
  },
  DELIVERY_TYPES: {
    DESK: 'deskDelivery',
    REMOTE: 'remote',
    MAILSTOP: 'mailstop',
    PICKUP: 'pickup',
  },
  SHIPPING_TYPES: {
    STANDARD: 'standard',
  },
  INTERNATIONAL_TYPES: {
    ADD: 'add',
    EXTEND: 'extend',
  },
  ORDER_STATUS: {
    RETURNED: 'returned',
    NEW: 'New',
  },
  VDI_STATUS: {
    ACTIVE: 'Active',
    AT_RISK: 'At Risk',
    PENDING: 'Pending 2FA',
    REQUESTED: 'Requested',
    MAINTENANCE: 'Maintenance',
    NEW: 'New',
    COMPLETED: 'Completed',
  },
  VDI_GUIDE: {
    FOLDER_NAME: 'virtual-pc',
    PREFIX: 'pdfs/',
    PDF_NAME: 'Virtual PC - Getting Started',
  },
  VDI_VERBIAGE: {
    DAILY_REQUEST_LIMIT_REACHED: (
      <span style={{ color: 'red' }}>
        You have reached the daily VDI request limit. Visit us at{' '}
        <a href="https://wmlink/vdiintake" target="_blank">
          wmlink/vdiintake
        </a>{' '}
        to raise further requests.
      </span>
    ),
    ANNUAL_REQUEST_LIMIT_REACHED: (
      <span style={{ color: 'red' }}>
        You have reached the annual VDI request limit. Visit us at{' '}
        <a href="https://wmlink/vdiintake" target="_blank">
          wmlink/vdiintake
        </a>{' '}
        to raise further requests.
      </span>
    ),
    currentDailyLimit(dailyLimit) {
      return (
        <span>
          You have <strong>{dailyLimit}</strong> daily VDI requests left.
        </span>
      );
    },
    currentAnnualLimit(annualLimit) {
      return (
        <span>
          You have <strong>{annualLimit}</strong> annual VDI requests left.
        </span>
      );
    },
  },
  CONFERENCE_COLLABORATION_TOOL: {
    TITLE: 'Setup',
    FOLDER_NAME: 'hybrid-meetings',
    PREFIX: 'pdfs/',
    SCHEDULING_MEETING_PDF:
      'Scheduling a meeting room from Outlook using Room Finder',
    ZOOM_ROOM_PDF: 'Zoom Room Quick Reference Guide',
    TOOLS: 'Tools',
  },
  HOLIDAY_READINESS: {
    TITLE:
      'Click on the following links to download the device specific requirements',
    FOLDER_NAME: 'holiday-readiness',
    PREFIX: 'pdfs/',
  },
  NOTES: {
    BUSINESS_JUSTIFICATION:
      'What business need is not being met with VPN (Cisco AnyConnect) while working from home?',
  },
  FORM_TEXT_TOOLTIP: {
    DEFAULT: 'Please tell us about your issue',
    COMPUTER_NAME:
      "To help troubleshoot your issue, please provide your computer's name",
  },
  FORM_LABELS: {
    BUSINESS_UNIT_IPAD_FORM_LABEL: 'Team / Department Name',
    BUSINESS_UNIT_LABEL: 'Department Name',
  },
  SINGLE_SPACE: ' ',
  EMPTY: '',
  DASH: '-',
  COMMA: ',',
  ROUTE_PARAM: ':name',
  AGREE: 'agree',
  MYSELF: 'myself',
  ANOTHER: 'another',
  MAILSTOP: 'mailstop',
  PICKUP: 'Pickup',
  REMOTE: 'remote',
  YES: 'Yes',
  NO: 'No',
  NETWORK_ERROR: 'Network Error',
  GENERIC_ERROR: 'Generic Error',
  TOKEN_ERROR: 'Token Error',
  UPN_ERROR: 'UPN_ERROR',
  SUBSEQUENT_CALLS: 'Subsequent calls',
  SHIFT_LABEL_CLASS: 'shiftLabel',
  WINDOWS: 'windows',
  WINDOWS_PC: 'pc',
  MAC: 'mac',
  TECH_BAR_LOCATIONS: 'tech-bar-locations',
  ARTICLE_PARAM_RESTRICTION: '(mac|pc)',
  MOBILE: 'mobile',
  CONFERENCE_COLLABORATION_TOOLS: 'conferenceCollaborationTools',
  WAYS_OF_WORKING: 'ways-of-working',
  MICROSOFT_TEAMS: 'microsoftTeams',
  ZOOM: 'zoom',
  VIDEO_CONFERNCE: 'videoConf',
  VIDEO_CONF_ISSUES: 'videoConfIssues',
  WORKPLACE: 'workplace',
  HOLIDAYS_READINESS: 'holidayReadiness',
  SECURITY_ACCESS: {
    ENROLLMENT_TYPES_2SV: {
      SYMANTEC_VIP_APP: 'Symantec VIP App',
      VOICE: 'Voice',
      TEXT: 'Text',
    },
    SSPR_AC_TYPES: { PRIMARY: 'PRIMARY', CM: 'CM', CMCN: 'CMCN' },
    SSPR_STATUS: {
      ONBOARD: 'ONBOARD',
      OPT_OUT: 'OPT_OUT',
      ELIGIBLE: 'ELIGIBLE',
      INELIGIBLE: 'INELIGIBLE',
    },
    SSPR_GROUP: {
      HEALTH_WELLNESS: 'AZ-GRP-SSPR-Health-Wellness',
      METHOD_OPT_OUT: 'AZ-GRP-Method-Opt-Out',
      EXCLUDE: 'AZ-GRP-SSPR-Exclude',
      OVERRIDE_INCLUDE: 'AZ-GRP-SSPR-Override-Include',
      BACKEND_OVERRIDE_INCLUDE: 'AZ-GRP-SSPR-Backend-Override-Include',
      OUT_OF_SCOPE: 'AZ-GRP-SSPR-Out-Of-Scope',
      ONBOARDING: 'AZ-GRP-SSPR-Onboarding',
      POST_CONVERGE: 'AZ-GRP-SSPR-Post-Converge',
      IN_SCOPE: 'AZ-GRP-SSPR-In-Scope',
      NULL_OR_NOT_RECOGNIZED: 'AZ-GRP-SSPR-EA10-Null-Or-Not-Recognized',
    },
    SSPR_MOCK_FORM_VALUES: {
      PHONE: 'MobilePhone',
      EMAIL: 'Email',
      AUTHENTICATOR_APP: 'AuthenticatorApp',
    },
    ENROLLMENT_STATUS: {
      ENROLLED: 'Enrolled',
      NOT_ENROLLED: 'Not Enrolled',
    },
    PASSWORD_STRENGTH: {
      STRONG: 'STRONG',
      NOT_STRONG: 'NOT STRONG',
      ENROLL_TEXT: 'Enroll in SSPR to see your password strength.',
    },
    PHISHING: {
      MIN_HIGH_VALUE: 0.6,
      MIN_ADVERAGE_VALUE: 0.1,
    },
    DIALOG_TYPES: {
      SSPR: 'SSPRFAQ',
      TWOSV: '2SVFAQ',
      PASSWORD_INFO: 'passwordInformationFAQ',
      PASSWORD_CHANGE: 'passwordChangeInstructions',
      PHISHING: 'phishingFAQ',
    },
    DATA_NOT_AVAILABLE: 'Data Currently Unavailable',
  },
  ONBOARDING: {
    ENROLLMENT_STATUS: {
      ENROLLED: 'Enrolled',
      NOT_ENROLLED: 'Not Enrolled',
      COMPLETE: 'Complete',
      NOT_COMPLETE: 'Not complete',
    },
    PASSWORD_STRENGTH: {
      STRONG: 'STRONG',
      NOT_STRONG: 'NOT STRONG',
      ENROLL_TEXT: 'Enroll in SSPR to see your password strength.',
    },
    DEVICE_TYPES: {
      MAC: 'mac',
      WINDOWS: 'windows',
    },
    NEW_HIRES: {
      FOLDER_NAME: 'new-hire',
      PDF_PREFIX: 'pdfs/',
      IMAGE_PREFIX: 'gallery/',
      THUMB_PREFIX: 'thumbnails/',
      MAC_PDF: 'New Hire Insert - Mac',
      PC_PDF: 'New Hire Insert - PC',
      CRT_PDF: 'New Hire Insert - CRT',
    },
  },
  BANNER_ID_TYPES: {
    HOTSPOT: 'hotSpot',
  },
  PAGINATION_PER_PAGE: {
    ORDERS: 5,
    TICKETS: 5,
    VITRUAL_PCS: 5,
  },
  FORM_NAMES: {
    CORP_INFORMATION: 'CORP_INFORMATION',
    CORP_DELIVERY: 'CORP_DELIVERY',
  },
  ALL_FOLDERS: '/**',
  ADMIN_USER_TYPES: {
    IMPERSONATED: 'IMPERSONATED',
    QA_USER: 'QA_USER',
    NON_QA_USER: 'NON_QA_USER',
  },

  JOB_POSITION_TYPES: {
    STORE_LEAD: 'Walmart Store People Lead',
    HOMEOFFICE_ASSOCIATE: 'Homeoffice Associate',
    FIELD_ASSOCIATE: 'Homeoffice Field Associate',
    GEC_ASSOCIATE: 'GEC Associate',
  },

  MAX_RETRY: 3,
  DASHBOARD_FEEDBACK: {
    LANDING: 'Landing',
    CANCELLED: 'Cancelled',
    SUBMITTED: 'Submitted',
  },
  APPS_FEEDBACK: {
    STAR_RATING_TITLE: 'How was your experience?',
    COMMENT_PLACEHOLDER: 'Please provide any details',
    COMMENT_CHAR_MAX: 3000,
    STAR_LABEL: 'Select a star rating (required)',
    COMMENT_CHAR_MAX_TEXT: `Your response should not exceed 3000 characters.`,
    SUBMITTED_TITLE: 'Thank you for your feedback!',
    SUBMITTED_TEXT:
      'Thank you for sharing about your experience. Our team works to provide the best user experience for Walmart associates.',
    CLOSE_ON_SUBMIT: 'Exit',
  },
  ORDERING_FEEDBACK: {
    TITLE: 'Please take a moment to provide feedback',
    QUESTION:
      'Did you find the information provided in Hardware Ordering useful?',
  },
  ORDERING: 'Ordering',
  SECURITY: 'Security',
  OTHER: 'Other',
  TRUE: 'true',
  FALSE: 'false',
  HOTS_DEVICE_TYPES: {
    COMPUTER: 'Computer',
    MOBILE: 'Mobile',
  },
  SNOW: {
    TIMEZONE: 'America/Chicago',
    TIME_FORMAT: 'MM-DD-YYYY HH:mm:ss',
    TYPE: {
      RITM: 'RITM',
      CONTACT: 'CONTACT',
      INCIDENT: 'INCIDENT',
    },
    STATUS: {
      CLOSED: 'Closed',
      CANCELED: 'Canceled',
      CLOSED_COMPLETE: 'Closed Complete',
      CLOSED_INCOMPLETE: 'Closed Incomplete',
    },
    COMMENT: {
      CODE_PREFIX: '[code]',
      CODE_POSTFIX: '[/code]',
    },
    TICKET_TABLE_NAME: {
      RITM: 'sc_req_item',
      CONTACT: 'u_contact',
      INCIDENT: 'incident',
    },
  },
  KEY_CODES: {
    ESCAPE_KEY: 27,
    TAB_KEY: 9,
    BACK_KEY: 8,
    SHIFT_KEY: 16,
    ARROW_DOWN: 40,
    ARROW_UP: 38,
    ALT_KEY: 18,
    ENTER_KEY: 13,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
  },
  MOBILE_TABLET: {
    DEVICES: {
      STORE_LEAD_REQUIRED_IPAD: '9.7” 6th Gen model iPad',
      IPAD_WIFI_ONLY: '10th Gen WiFi Only',
      IPAD_CELLULAR: '10th Gen Cellular',
      IPAD_MINI: 'iPad Mini 6th Gen Cellular – 64GB',
      IPAD_PRO: '11-inch iPad Pro Cellular',
      OTHER: 'Other',
    },
    BUSINESS_SEGMENT: {
      WALMART: 'Walmart',
      SAMS: "Sam's Club",
      HVAC: 'HVAC',
    },
    DEVICE_TYPES: {
      DEDICATED: 'Dedicated',
      SHARED: 'Shared',
    },
  },
  LOCATIONS: {
    Reston: 'Reston',
    Charlotte: 'Charlotte',
    California: 'California',
    Bentonville: 'Bentonville',
    Hoboken: 'Hoboken',
  },
  DIGITAL_SIGNAGE_OPTIONS: [
    { label: '', value: '' },
    { label: 'Bentonville', value: 'Bentonville' },
    { label: 'California', value: 'California' },
    { label: 'Charlotte', value: 'Charlotte' },
    { label: 'Hoboken', value: 'Hoboken' },
    { label: 'Reston', value: 'Reston' },
  ],
  US_VIDEO_CONF_LOCATIONS: [
    { label: '', value: '' },
    { label: 'Atlanta', value: '1900' },
    { label: 'Bentonville', value: '1100' },
    { label: 'California', value: '1200' },
    { label: 'Canada', value: '2000' },
    { label: 'Charlotte', value: '1500' },
    { label: 'Hoboken', value: '1300' },
    { label: 'Reston', value: '1400' },
    { label: 'Charlotte', value: '1500' },
    { label: 'Seattle', value: '2100' },
    { label: 'Texas', value: '1600' },
    { label: 'Washington D.C.', value: '2200' },
    { label: 'Distribution Center', value: '1700' },
    { label: 'Fulfillment Center', value: '1800' },
  ],
  IDC_VIDEO_CONF_LOCATIONS: [
    { value: '' },
    { value: 'Bengaluru' },
    { value: 'Chennai' },
    { value: 'Gurgaon' },
  ],
  STATE_DATA: [
    { value: 'Alabama', code: 'AL' },
    { value: 'Alaska', code: 'AK' },
    { value: 'Arizona', code: 'AZ' },
    { value: 'Arkansas', code: 'AR' },
    { value: 'California', code: 'CA' },
    { value: 'Colorado', code: 'CO' },
    { value: 'Connecticut', code: 'CT' },
    { value: 'Delaware', code: 'DE' },
    { value: 'District of Columbia', code: 'DC' },
    { value: 'Florida', code: 'FL' },
    { value: 'Georgia', code: 'GA' },
    { value: 'Hawaii', code: 'HI' },
    { value: 'Idaho', code: 'ID' },
    { value: 'Illinois', code: 'IL' },
    { value: 'Indiana', code: 'IN' },
    { value: 'Iowa', code: 'IA' },
    { value: 'Kansas', code: 'KS' },
    { value: 'Kentucky', code: 'KY' },
    { value: 'Louisiana', code: 'LA' },
    { value: 'Maine', code: 'ME' },
    { value: 'Maryland', code: 'MD' },
    { value: 'Massachusetts', code: 'MA' },
    { value: 'Michigan', code: 'MI' },
    { value: 'Minnesota', code: 'MN' },
    { value: 'Mississippi', code: 'MS' },
    { value: 'Missouri', code: 'MO' },
    { value: 'Montana', code: 'MT' },
    { value: 'Nebraska', code: 'NE' },
    { value: 'Nevada', code: 'NV' },
    { value: 'New Hampshire', code: 'NH' },
    { value: 'New Jersey', code: 'NJ' },
    { value: 'New Mexico', code: 'NM' },
    { value: 'New York', code: 'NY' },
    { value: 'North Carolina', code: 'NC' },
    { value: 'North Dakota', code: 'ND' },
    { value: 'Ohio', code: 'OH' },
    { value: 'Oklahoma', code: 'OK' },
    { value: 'Oregon', code: 'OR' },
    { value: 'Pennsylvania', code: 'PA' },
    { value: 'Puerto Rico', code: 'PR' },
    { value: 'Rhode Island', code: 'RI' },
    { value: 'South Carolina', code: 'SC' },
    { value: 'South Dakota', code: 'SD' },
    { value: 'Tennessee', code: 'TN' },
    { value: 'Texas', code: 'TX' },
    { value: 'Utah', code: 'UT' },
    { value: 'Vermont', code: 'VT' },
    { value: 'Virginia', code: 'VA' },
    { value: 'Washington', code: 'WA' },
    { value: 'West Virginia', code: 'WV' },
    { value: 'Wisconsin', code: 'WI' },
    { value: 'Wyoming', code: 'WY' },
  ],
  VIDEO_CONF: {
    FOLDER_NAME: 'video-conferencing',
    PREFIX: 'pdfs/',
    DOS_AND_DONTS_ROOM_BOOKING_PDF: "Dos and Don'ts for Room booking",
    ISSUES: {
      CAMERA: 'Camera (Video)',
      AUDIO: 'Audio',
      TOUCH_PANEL: 'Touch Panel System',
      BOOKING: 'Booking Scheduler',
      NETWORK: 'Network/System',
      OTHER: 'Other',
    },
  },
  HC_TICKET_TYPES: {
    HO_TECH_SUPPORT: 'Hardware issue',
    VDI_TECH_SUPPORT: 'Virtual device',
    VC_TECH_SUPPORT: 'Video Conferencing',
    APPLICATION_TECH_SUUPORT: 'Application',
    DIGITAL_SIGNAGE: 'Digital Signage (TVs)',
    OTHER_TECH_SUPPORT: 'Other',
  },
  HELP_CENTER_CATEGORY_IMAGE_TYPES: {
    COMPUTER: 'computer',
    PHONE: 'phone',
    WIFI: 'wifi',
    CONNECTION: 'connection',
    APPS: 'apps',
    DESK: 'desk',
    GIFT: 'gift',
    TEAMS: 'teams',
    ZOOM: 'zoom',
    VIDEO_CONF: 'videoConf',
    WORKPLACE: 'workplace',
  },
  SITE_SEARCH: {
    TYPE: {
      PDF: 'pdf',
      VIDEO: 'mp4',
      IMAGE: 'jpg',
      HTML: 'html',
    },
  },
  JWT_FIELD_NAMES: {
    EMAILID: 'email',
    UPN: 'upn',
  },
  BUSINESS_UNIT: {
    SAMS: "Sam's club",
    WALMART: 'Walmart',
  },
  STORE_CLUB_DC_OPTIONS: {
    STORES: 'Stores',
  },
  HELP_CENTER_TECH_BAR_LOCATIONS: {
    TITLE: 'Talk to an Expert',
    SUBTITLE: 'Speak with an expert in-person to resolve your issue.',
  },
  TOPIC_RETURN_TO_OFFICE: {
    TITLE: 'Return to Office',
    SUBTITLE:
      "Welcome back! Find useful information and how-to's to make your transition back to office as smooth as possible.",
  },
  TOPIC_HOLIDAY_READINESS: {
    TITLE: 'Holiday Readiness',
    SUBTITLE:
      'Prepare for the Holiday season! Find tips to keep your machine Happy, Healthy, and Safe.',
  },
  HELP_CENTER: {
    FOLDER_NAME: 'help-center',
    THUMB_PREFIX: 'thumbnails/',
  },
  SHIPMENT_TRACKER: {
    TITLE: 'Hardware Shipment Tracker',
    SUBTITLE:
      'Track the status of your shipment and return requests for laptops and other devices.',
    ALL_REQUESTS: {
      SEARCH_FIELD: {
        ASSOCIATE: 'Associate',
        ASSOCIATE_PLACEHOLDER: 'Associate name or ID',
        MANAGER: 'Manager',
        MANAGER_PLACEHOLDER: 'Manager name or ID',
        resultsFor(userInput) {
          return `Search results for '${userInput}'`;
        },
        noResultsFor(userInput) {
          return `No results for '${userInput}'`;
        },
      },
    },
  },
  ALERT_AUDIENCE_TYPES: {
    HO: 'ho',
    IDC: 'idc',
    WMSC: 'wmsc',
    NEW_HIRE: 'new hire',
    US: 'us',
    MX: 'mx',
    GEC: 'gec',
    STORE: 'store',
    STORE_CLUB_DC: 'store/club/dc',
    ALL: 'all',
  },
  OS_TYPES: {
    MACOS: 'macOS',
  },
  LOG_TYPES: {
    INFO: 'Information',
    TRACE: 'Trace',
    ERROR: 'Error',
  },
  IPAD_JOB_POSITIONS: {
    SENIOR_DIRECTOR: 'Senior Director',
    OPERATIONS_MANAGER: 'Operations Manager',
    OTHER_SUPPLY_CHAIN: 'Other Supply Chain Associate',
    ASSET_PROTECTION_OPS_LEAD: 'Asset Protection Ops Lead',
    DIGITAL_OPS_LEAD: 'Digital Ops Lead',
    FACILITIES_MAINTENANCE: 'Facilities Maintenance',
    HEALTH_CLINIC: 'Health Clinic (Haiku/Canto)',
    MARKER_MANAGER: 'Marker Manager',
    OTHER_HOME_OFFICE: 'Other Home Office Associate',
    PEOPLE_OPS_LEAD: 'People Ops Lead',
    REALITY_EXECUTION: 'Reality Execution',
    STORE_MANAGER: 'Store Manager',
    PEOPLR_LEAD: 'People Lead',
    CLUB_MANAGER: `Club Manager`,
  },
  EMPLOYEE_TYPE: {
    SALARY: 'S',
    HOURLY: 'H',
  },
  REPLACEMENT_DIALOGS: {
    SUBTITLE: 'Would you like to request a replacement device?',
    SECONDARY_INPUT_TEXT: 'No, I will do it later.',
  },
  SHOWBACK_ONLY_OPTIONS: [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ],
  CHARGEBACK_APPROVAL_OPTIONS: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: "Don't Know", value: 'unknown' },
  ],
  PRODUCT_TYPE_OPTIONS: [
    { label: 'Single License', value: 'singleLicense' },
    { label: 'Multi License', value: 'multiLicense' },
  ],
  LICENSE_TYPE: {
    PREMIUM: 'premium',
    ENTERPRISE: 'enterprise',
  },
  LICENSE_STATUS: {
    ACTIVE: 'active',
  },
  CHARGEBACK_LOCATION_OPTIONS: [
    { label: 'US', value: 'us' },
    { label: 'International', value: 'internatinoal' },
    { label: 'Both', value: 'both' },
  ],
  ONBOARD_LICENSE_FIELD_NAMES: [
    'mlProductName0',
    'mlProductDescription0',
    'mlADGroup0',
    'mlProductCost0',
    'mlProductName1',
    'mlProductDescription1',
    'mlADGroup1',
    'mlProductCost1',
    'productSuiteName',
  ],
  ONBOARD_LICENSE: {
    COST_CENTER: {
      ADE: 'US.09585',
      HELPER_TEXT:
        'Please only provide cost center for products outside of Associate Digital Experience.',
    },
  },
  ARIA_ROLES: {
    GROUP: 'group',
    REGION: 'region',
    DIALOG: 'dialog',
    BUTTON: 'button',
    TAB_PANEL: 'tabpanel',
    RADIO_GROUP: 'radiogroup',
    MENU: 'menu',
    MENU_ITEM: 'menuitem',
  },
  VIDEO_CAROUSEL_CURRENT_VIDEO_INIT_STATE: {
    src: '',
    title: ' ',
    searchError: false,
  },
  APPSTORE_CONTAINER: 'app-store',
  LICENSE_ICON_FOLDER: 'license-icon',
  FILEPOND_UPLOAD: {
    RETRY_LABEL: 'Click to retry',
    IDLE_LABEL: `Don't close or leave this page until all files are uploaded.`,
    UNSUPPORTED_LABEL: 'Please choose file with an image file type.',
  },
  CACHED_DATA: {
    DEVICE_DATA: 'deviceData',
  },
};

export default Consts;
