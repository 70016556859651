import { useHistory, useLocation } from 'react-router';
import MicroFrontend from '../../components/misc/MicroFrontend';
import MFEs from '../../consts/MFEs';
import UserType from '../../user/UserType';
import FlexPage from '../../components/page/FlexPage';
import { Collapse } from '@mui/material';
import Routes from '../../consts/Routes';
import AppContext from '../../app/context/AppContext';
import { useContext } from 'react';
import EnvConfigs from '../../Configurations';

const EquipmentRequest = () => {
  const location = useLocation();
  const history = useHistory();
  const { enabledMFEs } = useContext(AppContext);
  const showMFE =
    location.pathname?.startsWith(Routes.EQUIPMENT_REQUEST) &&
    enabledMFEs?.includes(MFEs.EQUIPMENTREQUEST);
  const getLoggedInUser = () => {
    if (UserType?.userData?.isImpersonated) {
      if (UserType.userData.UserPrincipalName) {
        return UserType.userData.UserPrincipalName;
      } else {
        return null;
      }
    } else {
      return UserType?.userData?.UserPrincipalName;
    }
  };

  return (
    <>
      <div className={showMFE ? 'main-content' : null}>
        <Collapse in={showMFE}>
          <FlexPage large hideHeader showHeader={false}>
            <MicroFrontend
              host={EnvConfigs.REACT_APP_EQUIPMENT_REQUEST_UI_HOST}
              proxyHost={EnvConfigs.REACT_APP_EQUIPMENT_REQUEST_UI_HOST}
              name={MFEs.EQUIPMENTREQUEST}
              mfeEnv={window.EQUIPMENT_REQUEST_UI.ENVIRONMENT}
              setMfeHost={() => {}}
              props={{
                history,
                impersonatedUser: getLoggedInUser(),
                isStandAlone: false,
                userUpn: UserType.userPrincipalName,
                userCN: UserType.displayName,
                userEmail: UserType.userEmail,
                userID: UserType.userPrincipalName.split('@')[0],
              }}
              handleSucess={() => {}}
              handleError={() => {}}
            />
          </FlexPage>
        </Collapse>
      </div>
    </>
  );
};

export default EquipmentRequest;
