import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EnvConfigs from '../../Configurations';
import { fetchMFEHeaders } from '../../utils/interceptor';
import { isHostHealthy } from '../../utils/helpers';
import Config from '../../utils/Config';
import LDLoading from './LDLoading';

function MicroFrontend({
  name,
  host, // direct url to mfe service.
  proxyHost, // proxy url to mfe service
  setMfeHost = () => {}, // sets the window var that mfe will use to make requests
  mfeEnv, // what env should mfe run in
  props,
  handleSuccess = () => {},
  handleError = () => {},
  container = false,
  disableOffNetwork = false,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const LOCAL = 'local';
  const getResource = (fileName, url) => {
    // TODO: WHEN PROXY IS READY IN PROD THIS IF/ELSE LOGIC CAN BE REMOVED
    const mfeAxios = axios.create({});
    if (Boolean(EnvConfigs.REACT_APP_PROXY_CONSUMER_ID)) {
      fetchMFEHeaders(mfeAxios);
      const config = {
        headers: {
          'WM_CONSUMER.ID': EnvConfigs.REACT_APP_PROXY_CONSUMER_ID,
        },
      };
      return mfeAxios?.get(`${url}${fileName}`, config);
    } else {
      return mfeAxios?.get(`${url}${fileName}`);
    }
  };
  useEffect(() => {
    if (!container) {
      try {
        const fetchFiles = async () => {
          setIsLoading(true);
          const scriptId = `micro-frontend-script-${name}`;
          let url = host;
          if (mfeEnv !== LOCAL) {
            const isOnNetwork = Config.isMFEProxyForced
              ? false
              : await isHostHealthy(host);
            if (!isOnNetwork && disableOffNetwork) {
              setIsLoading(false);
              handleError();
              return;
            }
            url = isOnNetwork ? host : proxyHost;
            setMfeHost(url);
          }

          const renderMicroFrontend = () => {
            window[`render${name}`] && window[`render${name}`](name, props);
          };
          if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            setIsLoading(false);
            handleSuccess();
            return;
          }
          const manifest = await getResource('/asset-manifest.json', url);
          if (!manifest?.data) {
            setIsLoading(false);
            handleError();
          } else {
            const response = manifest.data;

            const mainJS = await getResource(response.files['main.js'], url);

            const script = document.createElement('script');
            script.id = scriptId;
            script.crossOrigin = '';
            script.text = mainJS.data;
            document.head.appendChild(script);

            if (
              url.includes('mytech-hrt') ||
              url.includes('mytech-equipment-ordering')
            ) {
              const mainCSS = await getResource(
                response.files['main.css'],
                url
              );
              const style = document.createElement('style');
              style.type = 'text/css';
              style.appendChild(document.createTextNode(mainCSS.data));
              document.head.appendChild(style);
            }
            renderMicroFrontend();
            setIsLoading(false);
            handleSuccess();
          }
        };
        fetchFiles();
      } catch {
        setIsLoading(false);
        handleError();
      }
    }
  }, []);
  return (
    <>
      <div
        id={`${name}-${container ? 'container' : 'content'}`}
        className="mfe-container"
      />
      {isLoading && <LDLoading />}
    </>
  );
}
MicroFrontend.defaultProps = {
  document,
  window,
};
export default MicroFrontend;
